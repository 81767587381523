<template>
  <v-data-table
    :headers="headers"
    :items="product.product_vendors"
    single-expand
    :expanded.sync="expanded"
    item-key="vendor_data.id"
    show-expand
    hide-default-footer
  >
    <template v-slot:top>
      <v-dialog
        v-model="addVendorModal"
        max-width="800"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            rounded
            color="primary"
            >Adicionar fornecedor</v-btn
          >
        </template>
        <v-card min-height="500">
          <v-card-title class="primary white--text">
            <span>Adicionar fornecedor</span>
            <v-spacer />
            <v-btn
              @click="addVendorModal = false"
              icon
              color="white"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-text-field
              v-model="vendorSearch"
              append-icon="mdi-magnify"
              label="Pesquise o fornecedor aqui"
              single-line
              hide-details
              outlined
              rounded
              dense
            />
            <v-data-table
              :headers="vendorsHeader"
              :items="vendors"
              :search="vendorSearch"
              hide-default-footer
            >
              <template v-slot:[`item.add`]="{ item }">
                <v-btn
                  :disabled="
                    product.product_vendors.some(
                      (el) => el.vendor_data?.id == item?.id
                    )
                  "
                  color="primary"
                  icon
                  @click="addVendor(item)"
                >
                  <v-icon small>fas fa-plus</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.vendor_data?.company_name`]="{ item }">
      <p
      class="mb-0 primary--text subtitle-2 u-cursor-pointer"
        @click="
          $router.push({
            name: 'vendors-edit',
            params: { id: item?.vendor_data?.id },
          })
        "
      >
        {{ item.vendor_data?.company_name }}
      </p>
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      <span v-if="item.updated_at">{{
        item.updated_at.slice(0, 10).split('-').reverse().join('/')
      }}</span>
    </template>
    <template v-slot:[`item.selected`]="{ item }">
      <v-icon
        v-if="product.vendor_selected == item?.vendor_data?.id"
        color="primary"
        >fas fa-check-circle</v-icon
      >
      <v-radio-group
        v-else
        v-model="product.vendor_selected"
      >
        <v-radio :value="item?.vendor_data?.id" />
      </v-radio-group>
    </template>
    <template v-slot:[`item.remove`]="{ index }">
      <v-btn
        icon
        @click="product.product_vendors.splice(index, 1)"
        color="error"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        class="pa-0"
      >
        <v-expansion-panels
          flat
          mandatory
        >
          <v-expansion-panel class="transparent py-4">
            <v-expansion-panel-content>
              <v-textarea
                v-model="item.vendor_description"
                label="Observação"
                outlined
                rounded
                auto-grow
              />
              <div
                v-for="(costTable, idx) in item.cost_tables"
                :key="idx"
              >
                <CostTableCard
                  :print_types="product.product_print_types"
                  :costTable="costTable"
                  :tableIndex="idx"
                  @removeTable="item.cost_tables.splice(idx, 1)"
                />
              </div>
              <v-btn
                @click="addTable(item.cost_tables)"
                color="primary"
                class="ml-3"
                dense
                rounded
              >
                Adicionar tabela
                <v-icon
                  class="ml-2"
                  small
                  >fas fa-plus</v-icon
                >
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </td>
    </template>
    <template v-slot:[`item.vendor_code`]="{ item }">
      <v-text-field
        v-model="item.vendor_code"
        label="Cód."
        hide-details
        dense
        outlined
        rounded
      />
    </template>
    <template v-slot:[`item.vendor_status`]="{ item }">
      <v-item-group
        v-model="item.vendor_status"
        mandatory
      >
        <v-container class="d-flex justify-center">
          <v-item
            v-for="(status, idx) in vendorStatus"
            :key="idx"
            v-slot="{ active, toggle }"
            :value="idx + 1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  :color="active ? status.color : 'grey lighten-3'"
                  class="d-flex align-center justify-center mx-1"
                  height="30"
                  width="30"
                  v-on="on"
                  v-bind="attrs"
                  @click="toggle"
                  dark
                >
                  <v-icon
                    small
                    :color="active ? 'white' : 'grey lighten-1'"
                    >{{ status.icon }}</v-icon
                  >
                </v-card>
              </template>
              <span>{{ status.title }}</span>
            </v-tooltip>
          </v-item>
        </v-container>
      </v-item-group>
    </template>
    <template v-slot:[`item.vendor_cost`]="{ item }">
      <span v-if="item.cost_tables && item.cost_tables[0].is_fixed">{{
        item.cost_tables[0].rows[0].cost | moneyFormat
      }}</span>
      <span v-else>
        <v-icon
          color="primary"
          small
          >fas fa-table</v-icon
        >
        <span class="ml-2">{{ item.cost_tables | formatPlural }}</span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { contextMenu } from '@/main.js'
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    CostTableCard: () => import('./CostTableCard'),
  },
  filters: {
    moneyFormat(value) {
      if (typeof value == 'string') {
        if (value.includes(',')) {
          value = parseFloat(value.replace(',', '.'))
        }
      }

      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
    formatPlural(value) {
      if (value) {
        if (value.length != 1) {
          return value.length + ' Tabelas'
        } else {
          return value.length + ' Tabela'
        }
      }
    },
  },
  data: () => ({
    vendorIsSelected: null,
    vendors: [],
    addVendorModal: false,
    expanded: [],
    isDisabled: false,
    vendorSearch: null,
    vendorsHeader: [
      {
        text: 'Razão Social',
        value: 'company_name',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Nome fantasia',
        value: 'trading_name',
        sortable: true,
        filterable: true,
      },
      { text: 'CNPJ', value: 'cnpj', sortable: true, filterable: true },
      { text: '', value: 'add', sortable: false, filterable: false },
    ],
    headers: [
      {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'vendor_data.company_name',
      },
      {
        text: 'Custo fixo',
        align: 'center',
        sortable: false,
        value: 'vendor_cost',
      },
      {
        text: 'Data de atualização',
        align: 'center',
        sortable: false,
        value: 'updated_at',
      },
      {
        text: 'Código',
        align: 'center',
        sortable: false,
        value: 'vendor_code',
        width: 200,
      },
      {
        text: 'Status',
        align: 'center',
        sortable: false,
        value: 'vendor_status',
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'selected',
        width: 20,
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'remove',
        width: 20,
      },
      { text: '', value: 'data-table-expand' },
    ],
    vendorStatus: [
      { title: 'Ok', color: 'success', icon: 'fas fa-check-circle' },
      { title: 'Consultar', color: 'blue', icon: 'fas fa-search' },
      { title: 'Sem estoque', color: 'error', icon: 'fas fa-box-open' },
      { title: 'Fora de linha', color: 'grey', icon: 'fas fa-times-circle' },
    ],
  }),
  computed: {
    selectedVendor: function () {
      return this.product.product_vendors.filter((vendor) => vendor.selected)[0]
    },
    notSelectedVendors: function () {
      return this.product.product_vendors.filter(
        (vendor) => vendor.id != this.product.vendor_selected.id
      )
    },
  },
  watch: {
    vendorIsSelected(newvalue) {
      console.log(this.product.product_vendors)
      console.log(newvalue)
      if (
        this.product.product_vendors.find(
          (vendor) => vendor.id == newvalue.id
        ) ||
        this.product.vendor_selected.id == newvalue.id
      ) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 1:
          return 'success'
        case 2:
          return 'blue'
        case 3:
          return 'error'
        case 4:
          return 'grey'
      }
    },
    addTable(cost_tables) {
      cost_tables.push({ rows: [{}], printed: 1 })
    },
    getVendors() {
      this.$api
        .get('/vendors')
        .then((res) => {
          this.vendors = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    changeVendor(vendor) {
      if (this.product.vendor_selected.id) {
        this.product.product_vendors.push(this.product.vendor_selected)
      }

      this.product.vendor_selected = vendor
      let idx = this.product.product_vendors.indexOf(vendor)
      this.product.product_vendors.splice(idx, 1)
    },
    deleteVendor(vendor) {
      let idx = this.vendors.indexOf(vendor)
      this.product.product_vendors.splice(idx, 1)
      this.vendorIsSelected = null
    },
    deleteSelectedVendor() {
      this.product.vendor_selected.id = null
      this.vendorIsSelected = null
    },
    actionContextMenu(event, item) {
      contextMenu.open(event, item, 'vendors-card-context')
    },
    addVendor(vendor) {
      this.product.product_vendors.push({
        product_id: this.product.id,
        updated_at: new Date(Date.now()).toISOString(),
        vendor_data: vendor,
        vendor_code: '',
        vendor_status: 1,
        vendor_description: '',
        cost_tables: [
          {
            rows: [{}],
            printed: 0,
          },
        ],
      })
    },
  },
  mounted() {
    this.getVendors()
  },
}
</script>

<style>
tbody > tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background: #eee;
}
</style>
